import { Grid, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";

const useStyles = makeStyles({
  paymentButton: {
    color: "#ffffff",
    backgroundColor: "rgb(0, 85, 204)",
    borderRadius: "5px",
    cursor: "pointer",
    borderStyle: "none",
    userSelect: "none",
    outline: "none",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    padding: "12px",
    width: "100%",
    boxShadow: "1px",
    "&:disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      color: "rgba(0, 0, 0, 0.3)",
    },
  },
});

const PaymentForm = ({ square }: any) => {
  const classes = useStyles();
  const [card, setCard] = useState<any>();
  useEffect(() => {
    if (square) {
      const payments = square.payments(
        "sandbox-sq0idb-0pUC0UggUhFl_r0KWsXvHg",
        "LHQT1FPPQX2SB"
      );
      payments.card().then((card: any) => {
        try {
          card.attach("#card-container");
        } catch (e) {
          debugger;
        }
        setCard(card);
      });
    }
  }, [square]);

  const handlePayment = async () => {
    const result = await card.tokenize();
    if (result.status === "OK") {
      console.log(result);
    } else {
      console.log("error");
    }
  };

  return (
    <div>
      <div id="card-container"></div>
      <button
        className={classes.paymentButton}
        type="button"
        onClick={handlePayment}
      >
        Pay $1.00
      </button>
    </div>
  );
};

const SquarePayment = () => {
  const [isLoaded, setLoaded] = useState(true);
  //@ts-ignore
  return isLoaded ? <PaymentForm square={window.Square} /> : null;
};

export default SquarePayment;
