import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { ParallaxBanner } from "react-scroll-parallax";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles({
  root: {},
  landingZone: {
    position: "relative",
  },
  landingZoneLinkContainer: {
    position: "absolute",
    top: "70%",
    width: "100%",
    textAlign: "center",
  },
  landingZoneLink: {
    width: "50%",
  },
  landingZoneLinkButton: {
    fontSize: "1em",
    color: "white",
    border: "2px solid white",
    backgroundColor: "rgba(150,150,150,0.7)",
    "&:hover": {
      backgroundColor: "rgba(30,30,30,0.7)",
    },
  },
});

const CustomPots = () => (
  <Grid
    container
    direction="column"
    spacing={3}
    style={{
      width: "50%",
      margin: "30vh auto",
      backgroundColor: "rgba(255,255,255,0.55)",
      backdropFilter: "blur(5px)",
      color: "#292929",
    }}
  >
    <Grid item>
      <Typography variant="h1" align="center">
        Custom Pots
      </Typography>
    </Grid>
    <Grid item>
      <Typography align="justify">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </Typography>
    </Grid>
    <Grid item style={{ display: "flex", justifyContent: "center" }}>
      <Link to="/custom-pots" style={{ textDecoration: "none" }}>
        <Button variant="contained">Get Crafting</Button>
      </Link>
    </Grid>
  </Grid>
);

const CustomPotsParallax = () => (
  <ParallaxBanner
    layers={[
      {
        image: "/images/pots.jpeg",
        amount: 0.5,
      },
      {
        children: <CustomPots />,
        amount: 0,
      },
    ]}
    style={{
      height: "100vh",
      marginBottom: "3em",
    }}
    disabled={isMobile}
  />
);

interface IProductCard {
  id: number;
  name: string;
  description: string;
  price: number;
}

const ProductCard = ({ id, name, description, price }: IProductCard) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div style={{ height: "100%" }}>
      <Link
        to={`/product/${id}`}
        style={{
          textDecoration: "none",
          textAlign: "center",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card
          square
          style={{ width: "80%" }}
          onMouseOver={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
          elevation={isHover ? 10 : 2}
        >
          <CardHeader title={name} subheader={description} />
          <img
            src={`https://via.placeholder.com/150?text=${name}`}
            alt={name}
            style={{ objectFit: "cover", width: "100%" }}
          />
          <CardContent>
            <Typography>
              <NumberFormat
                value={price / 100}
                prefix="$"
                defaultValue={0}
                displayType="text"
              />
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </div>
  );
};

const products = [
  {
    id: 1,
    name: "Little Plant",
    description: "Little Description",
    price: 1200,
  },
  { id: 2, name: "Pot Holder", description: "Little Description", price: 800 },
  { id: 3, name: "Trellis", description: "Little Description", price: 2100 },
  { id: 4, name: "Plant Ring", description: "Little Description", price: 2600 },
  { id: 5, name: "Plant Cards", description: "Little Description", price: 499 },
  {
    id: 6,
    name: "Custom Jewelry",
    description: "Little Description",
    price: 3900,
  },
];

const HomePage = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item className={classes.landingZone}>
          <img
            src="/images/unbeleafable-home.jpg"
            alt="a"
            style={{ width: "100%", objectFit: "cover", height: "80vh" }}
          />
          <div className={classes.landingZoneLinkContainer}>
            <Link
              to="/about"
              className={classes.landingZoneLink}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="outlined"
                className={classes.landingZoneLinkButton}
              >
                Learn more about these Unbeleafable Babes!
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
      <CustomPotsParallax />
      <Container maxWidth="xl">
        <Grid container direction="column" spacing={3}>
          <Grid item container spacing={3}>
            {products.map((x, i) => (
              <Grid item key={i} xs={12} md={4}>
                <ProductCard {...x} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HomePage;
