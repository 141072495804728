import { Grid, Typography } from "@material-ui/core";
import SquarePayment from "../../shared/SquarePayment";

interface IItem {
  id: string;
  name: string;
  description: string;
  price: number;
  quantity: number;
}

const ItemCard = ({ id, name, description, price, quantity }: IItem) => (
  <Grid container>
    <Grid item container direction="column">
      <Grid item>
        <Typography variant="h2">{name}</Typography>
      </Grid>
      <Grid item>
        <Typography>{description}</Typography>
      </Grid>
    </Grid>
    <Grid item>
      {quantity} * {price} = {quantity * price}
    </Grid>
  </Grid>
);

const PaymentPage = () => {
  const onNonceReceived = (
    errors: any,
    nonce: any,
    cardData: any,
    buyerVerificationToken: any
  ) => {
    return null;
  };

  const cart = [
    {
      id: "asdf",
      name: "White Orchid",
      description: "White Orchid with a lovely aroma of life, love and family.",
      price: 1000,
      quantity: 2,
    },
    {
      id: "asdff",
      name: "16 inch custom pot",
      description: "Custom pot",
      price: 499,
      quantity: 1,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={9} container direction="column">
        <Grid item>
          <Typography variant="h1">Cart</Typography>
        </Grid>
        {cart.map((item, i) => (
          <Grid item key={i}>
            <ItemCard {...item} />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={3}>
        <SquarePayment />
      </Grid>
    </Grid>
  );
};

export default PaymentPage;
