import { Route, Switch, useRouteMatch } from "react-router-dom";

const BlogPost = () => <div>Blog Post</div>;

const BlogPostCard = () => <div>Blog Post Card</div>;

const BlogCollection = () => <div>Blog Collection</div>;

const BlogRoutes = () => {
  let { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${url}/:id`}>
        <BlogPost />
      </Route>
      <Route path={`${url}`}>
        <BlogCollection />
      </Route>
    </Switch>
  );
};

export default BlogRoutes;
