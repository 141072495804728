import { useState } from "react";
import {
  Button,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";

const CustomPotsPage = () => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const steps = [
    "Pot Size",
    "Unlaying Color",
    "Overlaying Color",
    "Custom Image",
  ];

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h1">Custom Pots</Typography>
      </Grid>
      <Grid item>
        <Stepper activeStep={activeStepIndex} orientation="vertical" nonLinear>
          <Step>
            <StepLabel onClick={()=>setActiveStepIndex(0)}>Pot Size</StepLabel>
            <StepContent>
              <Button onClick={() => setActiveStepIndex(activeStepIndex + 1)}>
                Next
              </Button>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Underlying Color</StepLabel>
            <StepContent>
              <Button onClick={() => setActiveStepIndex(activeStepIndex + 1)}>
                Next
              </Button>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Overlaying Color</StepLabel>
            <StepContent>
              <Button onClick={() => setActiveStepIndex(activeStepIndex + 1)}>
                Next
              </Button>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Custom Image</StepLabel>
            <StepContent>
              <Button onClick={() => setActiveStepIndex(activeStepIndex + 1)}>
                Next
              </Button>
            </StepContent>
          </Step>
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default CustomPotsPage;
