import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";

const AboutPage = () => {
  return (
    <Container maxWidth="xl">
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h1" align="center">
            About
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at nulla
            accumsan, condimentum nibh eget, consectetur dui. Nulla at felis sit
            amet odio ultricies tempus vitae eget felis. Nunc ultrices erat
            velit, quis consectetur mi commodo nec. Vivamus nec nibh eros.
            Nullam nec odio ipsum. Duis id tempus diam. Class aptent taciti
            sociosqu ad litora torquent per conubia nostra, per inceptos
            himenaeos. Nulla et placerat justo, at posuere sapien. Nullam sed
            varius mi. Aenean fermentum nibh non lacus blandit luctus.
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            Etiam euismod nulla eget ex posuere tempus. Donec sit amet est
            tempor, mollis libero sit amet, molestie est. Maecenas ligula diam,
            vulputate at orci in, finibus pellentesque lectus. Duis ut leo
            hendrerit, pretium lacus ut, elementum nibh. Nunc arcu nunc, tempor
            vel diam eu, dignissim laoreet risus. Etiam pellentesque luctus
            ornare. Cras eleifend pellentesque tellus aliquet malesuada. Integer
            a est id ante pulvinar bibendum. Sed purus nulla, vestibulum
            fringilla arcu nec, consequat pellentesque nunc.
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            Sed purus odio, finibus vitae viverra vel, commodo vel quam. Donec
            justo odio, auctor quis auctor posuere, pharetra non mi. Proin non
            sodales lacus. Fusce vulputate arcu ultrices mauris sagittis ornare.
            Sed imperdiet et ipsum ut malesuada. Phasellus tincidunt tincidunt
            risus varius venenatis. Curabitur arcu ante, convallis eu sodales
            quis, iaculis placerat libero. Aliquam imperdiet libero in nibh
            hendrerit, at rutrum nisi facilisis. Nulla lacinia enim sit amet sem
            auctor, nec tincidunt mi sodales. Praesent eget lobortis magna, ut
            consequat tellus. Quisque suscipit, orci accumsan finibus gravida,
            eros nunc porta mauris, sed venenatis tortor eros sit amet tortor.
            Sed accumsan leo non tortor laoreet tristique. In non varius eros,
            et hendrerit mi.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutPage;
