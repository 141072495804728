import {
  Container,
  Grid,
  IconButton,
  isWidthDown,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
  Typography,
  WithWidth,
  withWidth,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import BookIcon from "@material-ui/icons/Book";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import TreePlant from "./icons/TreePlant";

const useStyles = makeStyles({
  root: {
    height: "20vh",
    // backgroundColor: "green",
  },
  logoBar: { height: "15vh", textAlign: "center", width: "100%" },
  mainLinks: {
    height: "5vh",
    textAlign: "center",
    "& a": {
      padding: "5px 5px 5px 5px",
      cursor: "pointer",
      textDecoration: "none",
      color: "black",
    },
  },
  socialLinks: {
    color: "black",
    "&:hover": {
      color: "green",
    },
  },
});

const SocialNavigation = () => {
  const classes = useStyles();
  return (
    <>
      <Grid item>
        <a
          href="https://www.facebook.com/unbeleafable.babes/"
          aria-label="Unbeleafable Babes on Facebook"
          className={classes.socialLinks}
        >
          <FacebookIcon style={{ fontSize: "35px" }} />
        </a>
      </Grid>
      <Grid item>
        <a
          href="https://www.instagram.com/unbeleafable.babes/"
          aria-label="Unbeleafable Babes on Instagram"
          className={classes.socialLinks}
        >
          <InstagramIcon style={{ fontSize: "35px" }} />
        </a>
      </Grid>
    </>
  );
};

const CartNavigation = () => {
  return (
    <Grid item>
      <Link to="/cart">
        <IconButton>
          <ShoppingCartOutlinedIcon style={{ fontSize: "35px" }} />
        </IconButton>
      </Link>
    </Grid>
  );
};

const Navigation = ({ width }: WithWidth) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  let content = (
    <>
      <Grid item xs={3} container spacing={3} alignContent="center">
        <SocialNavigation />
      </Grid>
      <Grid item xs={6}>
        <Grid container direction="column">
          <Grid item className={classes.logoBar}>
            <Link to="/">
              <img src="/Unbeleafable-lips.png" alt="Home" height={"100%"} />
            </Link>
          </Grid>
          <Grid item className={classes.mainLinks}>
            <Link to="/blog">
              <Typography component="span">Blog</Typography>
            </Link>
            <Link to="/about">
              <Typography component="span">About</Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={3} alignContent="center" justify="flex-end">
        <CartNavigation />
      </Grid>
    </>
  );
  if (isWidthDown("sm", width)) {
    content = (
      <>
        <Grid container item xs={3} justify="flex-start" alignContent="center">
          <IconButton onClick={() => setDrawerOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} className={classes.logoBar}>
          <Link to="/">
            <img src="/Unbeleafable-lips.png" alt="Home" height={"100%"} />
          </Link>
        </Grid>
        <Grid container item xs={3} alignContent="center" justify="flex-end">
          <CartNavigation />
        </Grid>
      </>
    );
  }
  return (
    <nav className={classes.root}>
      <Container maxWidth="xl">
        <Grid container>{content}</Grid>
      </Container>
      <SwipeableDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => {}}
      >
        <List style={{ width: "200px" }}>
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>Home</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="/cart">
            <ListItemIcon>
              <ShoppingCartOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Cart</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="/box">
            <ListItemIcon>
              <CheckBoxOutlineBlankIcon />
            </ListItemIcon>
            <ListItemText>Box</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="/product/1">
            <ListItemIcon>
              <TreePlant />
            </ListItemIcon>
            <ListItemText>Plants &amp; Crafts</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="/blog">
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText>Blog</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="/about">
            <ListItemIcon>
              <PeopleOutlineIcon />
            </ListItemIcon>
            <ListItemText>About</ListItemText>
          </ListItem>
        </List>
      </SwipeableDrawer>
    </nav>
  );
};

export default withWidth()(Navigation);
