import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AboutPage from "./pages/about/AboutPage";
import BlogPage from "./pages/blog/BlogPage";
import CustomPotsPage from "./pages/custom-pots/CustomPotsPage";
import HomePage from "./pages/home/HomePage";
import PaymentPage from "./pages/payment/PaymentPage";
import ProductPage from "./pages/product/ProductPage";
import Navigation from "./shared/Navigation";

const Routes = () => (
  <Router>
    <Navigation />
    <Switch>
      <Route path="/product/:id">
        <ProductPage />
      </Route>
      <Route path="/custom-pots">
        <CustomPotsPage />
      </Route>
      <Route path="/about">
        <AboutPage />
      </Route>
      <Route path="/blog">
        <BlogPage />
      </Route>
      <Route path="/cart/pay">
        <PaymentPage />
      </Route>
      <Route path="/" exact>
        <HomePage />
      </Route>
    </Switch>
  </Router>
);

export default Routes;
