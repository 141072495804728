import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { useRouteMatch } from "react-router";

interface MatchParams {
  id: string;
}

const useStyles = makeStyles({
  root: {},
  thumbnailImage: {
    width: "75",
    height: "75",
    objectFit: "cover",
    cursor: "pointer",
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "transparent",
    "&:hover": {
      borderColor: "Yellow",
    },
  },
  selectedImage: {
    width: "95%",
    height: "60vh",
    objectFit: "cover",
  },
  formOption: {
    width: "100%",
  },
  addToCart: {
    width: "60%",
    textAlign: "center",
  },
});

interface IProductImages {
  images: string[];
}

const ProductImages = (props: IProductImages) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const classes = useStyles();
  return (
    <Grid container direction="row">
      <Grid item container direction="column" xs={2}>
        {props.images.map((x, i) => {
          return (
            <Grid item>
              <img
                src={x}
                alt="Todo description"
                className={classes.thumbnailImage}
                width={75}
                height={75}
                onClick={() => setSelectedImage(i)}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={10}>
        <img
          src={props.images[selectedImage]}
          alt="Todo description"
          className={classes.selectedImage}
        />
      </Grid>
    </Grid>
  );
};

const ProductPage = () => {
  const [formValues, setFormValues] = useState<any>({});
  const match = useRouteMatch<MatchParams>("/product/:id");
  const classes = useStyles();

  const product = {
    id: match?.params.id,
    name: "Test Product",
    description:
      "Test Product is great for having in your home, and everyone will love it.",
    images: [
      "https://images.unsplash.com/photo-1501004318641-b39e6451bec6?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8cGxhbnR8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
      "https://images.unsplash.com/photo-1497250681960-ef046c08a56e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8M3x8cGxhbnR8ZW58MHx8MHw%3D&auto=format&fit=crop&w=800&q=60",
      "https://images.unsplash.com/photo-1459156212016-c812468e2115?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NXx8cGxhbnR8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
      "https://images.unsplash.com/photo-1545147986-a9d6f2ab03b5?ixid=MXwxMjA3fDB8MHxzZWFyY2h8OHx8cGxhbnR8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
      "https://images.unsplash.com/photo-1512428813834-c702c7702b78?ixid=MXwxMjA3fDB8MHxzZWFyY2h8OXx8cGxhbnR8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
    ],
    price: 19.0,
    options: [
      {
        name: "Pot Size",
        description: "Pot Size Description",
        options: ["small", "medium", "large"],
      },
    ],
  };

  return (
    <Grid container direction="row">
      <Grid container item direction="row" lg={6}>
        <ProductImages images={product.images} />
      </Grid>
      <Grid item lg={6}>
        <Typography variant="h1">{product.name}</Typography>
        <Typography>
          <NumberFormat
            value={product.price}
            prefix={"$"}
            displayType="text"
            decimalScale={2}
            decimalSeparator="."
            fixedDecimalScale={true}
          />
        </Typography>
        <Typography>{product.description}</Typography>
        <form
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <Grid
            container
            direction="column"
            spacing={3}
            style={{ paddingTop: 15 }}
          >
            {product.options &&
              product.options.map((x, i) => {
                const id = `option${i}`;
                return (
                  <Grid item key={id}>
                    <FormControl className={classes.formOption}>
                      <InputLabel htmlFor={id}>{x.name}</InputLabel>
                      <Select
                        labelId={`${id}-label`}
                        id={id}
                        value={formValues[id] ?? x.options[0]}
                        onChange={(event) =>
                          setFormValues({
                            ...formValues,
                            [id]: event.target.value,
                          })
                        }
                      >
                        {x.options.map((opt) => (
                          <MenuItem value={opt} key={opt}>
                            {opt}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText id={`${id}-desc`}>
                        {x.description}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                );
              })}
            <Grid item>
              <FormControl className={classes.formOption}>
                <TextField
                  id="quantity"
                  label="Quantity"
                  type="number"
                  value={formValues["quantity"] ?? 1}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      quantity: event.target.value,
                    })
                  }
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </FormControl>
            </Grid>
            <Grid item style={{ textAlign: "center" }}>
              <Button type="submit" className={classes.addToCart}>
                Add To Cart
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ProductPage;
